module.exports = [{
      plugin: require('/home/user/Code/redux-site.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"backgroundColor":"transparent"},
    },{
      plugin: require('/home/user/Code/redux-site.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/user/Code/redux-site.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
