// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/user/Code/redux-site.github.io/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/user/Code/redux-site.github.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-advisory-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/advisory/index.js" /* webpackChunkName: "component---src-pages-advisory-index-js" */),
  "component---src-pages-contact-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-dmiagreement-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/dmiagreement/index.js" /* webpackChunkName: "component---src-pages-dmiagreement-index-js" */),
  "component---src-pages-faq-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-grievance-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/grievance/index.js" /* webpackChunkName: "component---src-pages-grievance-index-js" */),
  "component---src-pages-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/index/index.js" /* webpackChunkName: "component---src-pages-index-index-js" */),
  "component---src-pages-interest-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/interest/index.js" /* webpackChunkName: "component---src-pages-interest-index-js" */),
  "component---src-pages-personal-loan-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/personal-loan/index.js" /* webpackChunkName: "component---src-pages-personal-loan-index-js" */),
  "component---src-pages-privacy-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("/home/user/Code/redux-site.github.io/src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/user/Code/redux-site.github.io/.cache/data.json")

